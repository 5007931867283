/**
* Generated automatically at built-time (2025-04-01T07:51:54.799Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "3d-guide-udekokken",templateKey: "sites/103-f55bc70e-a94e-4998-bc45-ab6874d94260"};